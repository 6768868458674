import 'react-datepicker/dist/react-datepicker.css'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { history } from '../redux/stores'

var componentRequireContext = require.context('routes', true)
var ReactRailsUJS = require('react_ujs')
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext)

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register('/serviceworker.js', { scope: './' })
    .then(function (reg) {
      console.log('Service worker registered!')
    })

  navigator.serviceWorker.addEventListener('message', (event) => {
    switch (event.data.type) {
      case 'NAVIGATE':
        return history.push(event.data.url || '/notifications')
      default:
        console.log('skipping', event.data.type)
    }
  })
}

document.addEventListener('DOMContentLoaded', (event) => {
  console.log('PACK app.js loaded')
})
